<template>
  <div class="pbf grid_width" v-if="activity_data">
 

    <div class="proslist">


      <SkBasicRows 
        v-if="is_loading && !following_data?.items_following?.length"
        />
 
      <template v-else v-for="pro in activity_data">
        <div class="last_update" v-if="pro.show_last_update">
          <i class="icon minus"></i> {{ $dayjs.utc(pro.last_update).fromNow() }} <i class="icon minus"></i>
        </div>
        <GrowerActivityItem
          :data="pro"
          />
      </template>

      <client-only>        
        <InfinityScroll 
          v-if="!is_ended"
          :is-loading="is_loading"
          ref="el_infinity_scroll"/>
      </client-only>

    </div>
  

  </div>
</template>

<script setup>

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const { $api } = useNuxtApp();

const props = defineProps({
  grower: {
    type: String,
    required: true
  },
  inurl: {
    type: String,
    required: true
  },
});

const el_infinity_scroll = ref(null)
const url = ref(props.inurl);
const start = ref(0);
const limit = ref(20);
const list = ref([]);
const last_update = ref('');
const is_ended = ref(false);

const clearPage = () => {
  start.value = 0;
  list.value = [];
}

const infiniteHandler = (state) => {
  start.value += limit.value;
  loadResults(state);
}



const loadData = async function() {  
  const response = await $api.getGrowerActivity(props.grower, {
    start: start.value,
    limit: limit.value,
  });
  return response;  
}



const { pending: is_loading,  data: activity_data } = await useLazyAsyncData('activity_data', async () => await loadData())


watchArray([start], async ([new_start], added, removed) => {


  if(new_start == 0){
    activity_data.value = [];
    is_ended.value = false;
  }

  console.log('watcher');
  is_loading.value = true;
  var dt = await loadData();
    
  if(dt.length == 0)
    is_ended.value = true;  

  activity_data.value = [...activity_data.value, ...dt]
  is_loading.value = false;

  // $adv.apply();

}, {deep: true})


useIntersectionObserver(
  el_infinity_scroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !is_ended.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)





</script>

<style scoped>

 

.proslist {

}


.proslist.centered {
}

.proslist > .last_update{
    text-align: center;
    padding: 0rem 1.5rem 1.5rem 1.5rem;
    color: #cacaca;
    font-weight: bold;
    margin-top: 20px;
}

</style>
